<template>
  <div class="wrap">
    <div class="banner">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">企业招聘</p>
      </div>
    </div>
    <div class="tab-wrap">
      <div class="tab"
           :class="{'fixed': isFixed}">
        <div class="inner">
          <a href="javascript:;"
             :class="{active:index === isActive}"
             v-for="(item, index) in jobTitle"
             :key="index"
             @click="goAnchor('#a' + (index + 1), index)">{{ item }}</a>
        </div>
      </div>
    </div>
    <div class="inner">
      <div class="tab-content">
        <h4 id="a1">JAVA工程师</h4>
        <div class="item">
          <p class="strong">岗位描述：</p>
          <p>1. 负责工业物联网IoT平台软件开发和维护包括软件设计、实现、测试、集成/释放和修复bug；</p>
          <p>2. 参与需求/用户分析和支持产品所有者和软件架构师和架构分析的解决方案；</p>
          <p>3. 实现用户故事在开发迭代,包括任务分解、工作评估和规划,实现,测试,演示；</p>
          <p>4. 确保软件代码质量、可测试性、可维护性和可用性的函数；</p>
          <p>5. 与技术撰写员共事并提供文档输入资料；</p>
        </div>
        <div class="item">
          <p class="strong">任职需求：</p>
          <p>1、计算机相关专业本科及以上学历；</p>
          <p>2、3-5年Java开发经验，熟悉J2EE开发，主流应用服务器Tomcat至少熟悉一种；</p>
          <p>3、扎实的Java编程基础，熟悉常用的设计模式，多线程编程，具备良好的编码实践，善于重构和Code Review的优先考虑；</p>
          <p>4、熟练使用主流开发框架（Spring, Hibernate 等）；</p>
          <p>5、具有基于分布式系统的开发经验以及基于Linux平台的开发经验；</p>
          <p>6、熟悉SQL，熟练使用Postgres/MySQL数据库；</p>
          <p>7、熟悉持续集成（CI），有Jenkins使用经验；</p>
          <p>8、具有使用流行框架（比如HTML/AJAX/JavaScript）构建Web GUI的经验；</p>
          <p>9、了解基本的测试流程和测试工具，熟悉单元测试和功能测试；</p>
          <p>10、熟悉以下技术或者有使用以下技术开发的经验优先考虑：</p>
          <p>有高并发,大数据量处理经验优先</p>
          <p>Git</p>
          <p>Docker/Kubernetes</p>
          <p>Redis</p>
          <p>Maven/Gradle</p>
          <p>NoSQL</p>
        </div>
        <h4 id="a2">电气工程师</h4>
        <div class="item">
          <p class="strong">岗位描述：</p>
          <p>1. 负责工业物联网IoT平台软件开发和维护包括软件设计、实现、测试、集成/释放和修复bug；</p>
          <p>2. 参与需求/用户分析和支持产品所有者和软件架构师和架构分析的解决方案；</p>
          <p>3. 实现用户故事在开发迭代,包括任务分解、工作评估和规划,实现,测试,演示；</p>
          <p>4. 确保软件代码质量、可测试性、可维护性和可用性的函数；</p>
          <p>5. 与技术撰写员共事并提供文档输入资料；</p>
        </div>
        <h4 id="a3">phython工程师</h4>
        <div class="item">
          <p class="strong">岗位描述：</p>
          <p>1. 负责工业物联网IoT平台软件开发和维护包括软件设计、实现、测试、集成/释放和修复bug；</p>
          <p>2. 参与需求/用户分析和支持产品所有者和软件架构师和架构分析的解决方案；</p>
          <p>3. 实现用户故事在开发迭代,包括任务分解、工作评估和规划,实现,测试,演示；</p>
          <p>4. 确保软件代码质量、可测试性、可维护性和可用性的函数；</p>
          <p>5. 与技术撰写员共事并提供文档输入资料；</p>
        </div>
        <div class="item">
          <p class="strong">任职需求：</p>
          <p>1、至少具有三年以上Python网站开发经验；</p>
          <p>2、至少熟悉一种Python框架；</p>
          <p>3、熟悉MySQL数据库设计和查询优化, 会ORM框架sqlalchemy更好；</p>
          <p>4、熟悉基于Rabbitmq的开发；</p>
          <p>5、熟悉ElasticSearch索引的创建和查询；</p>
          <p>6、具有较强分析和解决问题的能力；</p>
          <p>7、具有较强API设计的能力。</p>
        </div>
        <h4 id="a4">前端工程师</h4>
        <div class="item">
          <p class="strong">岗位描述：</p>
          <p>1. 负责工业物联网IoT平台软件开发和维护包括软件设计、实现、测试、集成/释放和修复bug；</p>
          <p>2. 参与需求/用户分析和支持产品所有者和软件架构师和架构分析的解决方案；</p>
          <p>3. 实现用户故事在开发迭代,包括任务分解、工作评估和规划,实现,测试,演示；</p>
          <p>4. 确保软件代码质量、可测试性、可维护性和可用性的函数；</p>
          <p>5. 与技术撰写员共事并提供文档输入资料；</p>
        </div>
        <div class="item">
          <p class="strong">任职需求：</p>
          <p>1、3年或以上的Web前端开发经验，计算机或相关专业、本科及以上学历；</p>
          <p>2、基础扎实，熟悉常用的数据结构和算法；掌握操作系统与计算机网络等专业知识；</p>
          <p>3、熟悉HTML、JavaScript、Ajax、CSS、HTML5、ES6、CSS3等web开发技术；</p>
          <p>4、掌握至少一种主流的前端框架，对vue或者react有丰富的开发经验，对于可视化开发有一定经验，具备一定的框架设计能力；</p>
          <p>5 、熟悉Web应用系统开发，对HTTP、TCP/IP协议有深入了解；有性能优化经验；拥有良好的安全意识，熟悉常见的网络安全攻防策略；</p>
          <p>6、至少了解一种服务器编程语言，有nodejs开发经验，对前后端分离模式有深入理解并有实际项目经验；对服务可用性及大规模服务相关知识有一定的了解；</p>
          <p>7、具有较强的沟通能力与团队合作精神；具有良好的分析问题、解决问题的能力以及较强的学习能力； 有强烈的创新精神并能付诸实施，能积极学习业界新技术； 有技术追求。</p>
        </div>
        <div class="item">
          <p class="strong">联系方式：</p>
          <p>电话：027-65527829</p>
          <p>邮箱：ytwd@ytwdwh.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Job',
  props: {
    scrollTopNum: Number
  },
  data () {
    return {
      isActive: 0,
      jobTitle: ['JAVA工程师', '电气工程师', 'phython工程师', '前端工程师'],
      isFixed: false,
      pageTop: {
        a1: 0,
        a2: 0,
        a3: 0,
        a4: 0
      }
    }
  },
  mounted () {
    this.pageTop.a1 = this.$el.querySelector('#a1').offsetTop
    this.pageTop.a2 = this.$el.querySelector('#a2').offsetTop
    this.pageTop.a3 = this.$el.querySelector('#a3').offsetTop
    this.pageTop.a4 = this.$el.querySelector('#a4').offsetTop
  },
  watch: {
    scrollTopNum (val) {
      // val > 450 ? this.isFixed = true : this.isFixed = false
      // 距离顶部距离设置为固定
      val > this.pageTop.a1 ? this.isFixed = true : this.isFixed = false
      // 根据顶部距离定位锚点
      if (val > this.pageTop.a1 && val < this.pageTop.a2) {
        this.isActive = 0
      } else if (val > this.pageTop.a2 && val < this.pageTop.a3) {
        this.isActive = 1
      } else if (val > this.pageTop.a3 && val < this.pageTop.a4) {
        this.isActive = 2
      } else if (val > this.pageTop.a4) {
        this.isActive = 3
      }
    }
  },
  methods: {
    goAnchor (selector, index) {
      // this.isActive = index
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start' // 上边框与视窗顶部平齐, 默认值
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url("../../assets/images/banner2.png") no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url("../../assets/images/banner2-icon.png") no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.tab-wrap {
  position: relative;
  height: 100px;
}
.tab {
  background: #fafafa;
  width: 100%;
  height: 100px;
  padding: 30px 0;
  position: absolute;
  top: 0;
  right: 10px;
  &.fixed {
    position: fixed;
    width: 150px;
    // top: 76px;
    top: 100px;
    // left: 50%;
    // margin-left: 450px;
    background: none;
    .inner {
      width: 100%;
    }
    a {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
  .inner {
    width: 1200px;
    margin: 0 auto;
  }
  a {
    font-size: 16px;
    color: #00dcd4;
    background-color: #fff;
    width: 150px;
    line-height: 38px;
    border: 1px solid #00dcd4;
    text-align: center;
    border-radius: 20px;
    margin-left: 133px;
    display: inline-block;
    // &:hover
    &.active {
      background: #00dcd4;
      color: #fff;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.tab-content {
  margin: 20px 0 50px;
  h4 {
    font-size: 30px;
    padding-top: 85px;
    color: #00dcd4;
  }
  .item {
    margin-top: 48px;
    // margin-bottom: 48px;
    .strong {
      color: #434343;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 18px;
    }
    p {
      font-size: 18px;
      line-height: 36px;
      padding-right: 260px;
    }
  }
}
</style>
